import { Helmet } from "react-helmet";

import { Features, Hero, PaymentData } from "../features/business";
import { Future } from "../features/home";
import { MainLayout } from "../layout";

export const Business = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CoAuth | Business</title>
        <meta
          name="description"
          content={
            "This is the CoAuth business page that shows the features we offer for business users"
          }
        />
      </Helmet>

      <MainLayout>
        <Hero />
        <Features />
        <PaymentData />
        <Future
          header="Let’s build the future of finance together"
          body="Access the open finance capabilities you need from CoAuth."
        />
      </MainLayout>
    </div>
  );
};
