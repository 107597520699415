export const Analytics: React.FC<any> = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.2441" cy="9.74359" r="9.74359" fill="#4ED17E" />
      <path
        d="M12 24.6667H5.33333V14H12V24.6667ZM9.33333 22V16.6667H8V22H9.33333ZM17.3333 22V11.3334H16V22H17.3333ZM20 24.6667H13.3333V8.66671H20V24.6667ZM25.3333 22V6.00004H24V22H25.3333ZM28 24.6667H21.3333V3.33337H28V24.6667ZM29.3333 30H4V27.3334H29.3333V30Z"
        fill="black"
      />
    </svg>
  );
};
