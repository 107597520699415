import { Helmet } from "react-helmet";

import { JoinWaitlistForm } from "../features/join-waitlist";
import { MainLayout } from "../layout";

export const JoinWaitlist = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CoAuth | Join Waitlist</title>
        <meta
          name="description"
          content={
            "This is the CoAuth waitlist form to be one of the users notified when we have new features or release new updates"
          }
        />
      </Helmet>

      <MainLayout>
        <JoinWaitlistForm />
      </MainLayout>
    </div>
  );
};
