import {
  Box,
  Heading,
  Image,
  Icon,
  Button,
  Grid,
  GridItem,
  Flex,
} from "@chakra-ui/react";
import { FiPlayCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

// import { MainContainer } from "../../layout";
import overview from "../../assets/images/overview.png";

export const Hero = () => {
  const navigate = useNavigate();

  // const calculatedHeight = `calc(100vh - 80px)`;

  return (
    <Box
      pl={{ base: "5%", sm: "7%", xl: "133px" }}
      pr={{ base: "5%", sm: "7%", md: "0" }}
      maxW={["100%"]}
      // h={calculatedHeight}
    >
      {/* <MainContainer bg="transparent"> */}
      <Grid
        pt="3rem"
        gap={{ base: "3rem", xl: "4rem" }}
        gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }}
      >
        <GridItem display={{ base: "block", md: "none" }}>
          <Heading
            fontSize={{ base: "2rem", xl: "3rem" }}
            color="brand.gray.900"
            mb="4rem"
          >
            Secure and trustworthy way for customers to link their financial
            accounts.
          </Heading>

          <Flex align="center" justify="center">
            <Image src={overview} alt="phone" h={"18rem"} />
          </Flex>
        </GridItem>

        <GridItem>
          <Heading
            fontSize={{ base: "2rem", xl: "3rem" }}
            color="brand.gray.900"
            mt={{ base: "0", md: "5rem" }}
            display={{ base: "none", md: "block" }}
          >
            Secure and trustworthy way for customers to link their financial
            accounts.
          </Heading>

          <Flex
            my={{ base: "0", md: "3rem" }}
            gap="0.8rem"
            flexDir={{ base: "column", md: "row" }}
          >
            <Button
              color="brand.gray.500"
              border="1px"
              borderColor="brand.gray.300"
              bg="white"
              size="lg"
              leftIcon={
                <Icon
                  as={FiPlayCircle}
                  color="brand.gray.700"
                  w="1.5rem"
                  h="1.5rem"
                />
              }
              onClick={() => navigate("/demo")}
            >
              Demo
            </Button>

            <Button
              bg="brand.primary.500"
              color="white"
              size="lg"
              _hover={{ bg: "brand.primary.500" }}
              onClick={() => navigate("/join-waitlist")}
            >
              Join waitlist
            </Button>
          </Flex>
        </GridItem>

        <GridItem display={{ base: "none", md: "block" }}>
          <Flex align="center" justify="center">
            <Image
              src={overview}
              alt="phone"
              objectFit={"contain"}
            />
          </Flex>
        </GridItem>
      </Grid>
      {/* </MainContainer> */}
    </Box>
  );
};
