export const Card: React.FC<any> = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.2441" cy="9.74359" r="9.74359" fill="#4ED17E" />
      <path
        d="M4.00667 4.66675H28.0067C28.7431 4.66675 29.34 5.26371 29.34 6.00008V27.3334C29.34 28.0698 28.7431 28.6667 28.0067 28.6667H4.00667C3.2703 28.6667 2.67334 28.0698 2.67334 27.3334V6.00008C2.67334 5.26371 3.2703 4.66675 4.00667 4.66675ZM26.6734 16.6667H5.34001V26.0001H26.6734V16.6667ZM26.6734 11.3334V7.33341H5.34001V11.3334H26.6734Z"
        fill="black"
      />
    </svg>
  );
};
