export const Reliability: React.FC<any> = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.2446" cy="9.74359" r="9.74359" fill="#4ED17E" />
      <path
        d="M6.67318 12.6702C7.40956 12.6702 8.00651 13.2671 8.00651 14.0036C10.1862 14.0036 12.1914 14.7506 13.7799 16.0029L16.6732 16.0036C18.4493 16.0036 20.0452 16.7753 21.1439 18.0018L25.3399 18.0036C27.9963 18.0036 30.2897 19.5572 31.3613 21.8055C28.2083 25.9662 23.1024 28.6702 17.3399 28.6702C13.6189 28.6702 10.4729 27.8659 7.92534 26.4596C7.74076 26.9709 7.2497 27.3369 6.67318 27.3369H2.67318C1.9368 27.3369 1.33984 26.7399 1.33984 26.0036V14.0036C1.33984 13.2671 1.9368 12.6702 2.67318 12.6702H6.67318ZM8.00786 16.6702L8.00651 23.3651L8.06699 23.4094C10.4587 25.0901 13.5773 26.0036 17.3399 26.0036C21.3452 26.0036 25.0713 24.4627 27.7867 21.8309L27.9639 21.6529L27.8049 21.5191C27.1863 21.035 26.4273 20.7346 25.6132 20.6794L25.3399 20.6702L22.5243 20.6689C22.6217 21.0981 22.6732 21.5448 22.6732 22.0036V23.3369H10.6732V20.6702L19.7265 20.6689L19.6807 20.5643C19.1733 19.5061 18.124 18.7572 16.8924 18.6773L16.6732 18.6702L12.7673 18.67C11.5576 17.4361 9.87216 16.6705 8.00786 16.6702ZM5.33984 15.3369H4.00651V24.6702H5.33984V15.3369ZM18.2017 5.43737L18.6728 5.90905L19.1445 5.43737C20.4463 4.13562 22.5569 4.13562 23.8587 5.43737C25.1604 6.73912 25.1604 8.84966 23.8587 10.1514L18.6732 15.3369L13.4877 10.1514C12.186 8.84966 12.186 6.73912 13.4877 5.43737C14.7895 4.13562 16.9 4.13562 18.2017 5.43737ZM15.3733 7.32298C15.1419 7.55441 15.1163 7.91364 15.2952 8.17246L15.3719 8.26437L18.6719 11.5649L21.9731 8.2658C22.2044 8.03437 22.2301 7.67514 22.0501 7.41532L21.9731 7.32298C21.7416 7.09156 21.3824 7.06585 21.1223 7.24604L21.0299 7.32326L18.6711 9.68142L16.3147 7.32156L16.2239 7.24584C15.964 7.06585 15.6048 7.09156 15.3733 7.32298Z"
        fill="black"
      />
    </svg>
  );
};
