import { Box, Heading, SimpleGrid } from "@chakra-ui/react";

import { MainContainer } from "../../layout";
import { Feature } from "../../components";
import { Security } from "../../assets/svg/Security";
import { Code } from "../../assets/svg/Code";
import { Brush } from "../../assets/svg/Brush";
import { Analytics } from "../../assets/svg/Analytics";
import { Reliability } from "../../assets/svg/Reliability";
import { Key } from "../../assets/svg/Key";

export const Features = () => {
  const features = [
    {
      id: 1,
      icon: <Security />,
      title: "Bank Grade Security",
      body: "SCA Complaint with biometrics and encryption to eliminate fraud.",
      svg: true,
    },
    {
      id: 2,
      icon: <Code />,
      title: "Easy Integration",
      body: "Rapid and flexible deployment.",
      svg: true,
    },
    {
      id: 3,
      icon: <Brush />,
      title: "Seamless Experience",
      body: "Frictionless, aesthetically pleasing, and swift connection experience.",
      svg: true,
    },
    {
      id: 4,
      icon: <Analytics />,
      title: "Robust Analytics",
      body: "Understand the real time authentication requests of your customers.",
      svg: true,
    },
    {
      id: 5,
      icon: <Reliability />,
      title: "Enhanced Reliability",
      body: "Direct and safe integrations with our partners.",
      svg: true,
    },
    {
      id: 6,
      icon: <Key />,
      title: "Instant Access",
      body: "Get account information, along with instant account based payment, settlements and refunds.",
      svg: true,
    },
  ];

  return (
    <Box bg="white" py="96px">
      <MainContainer bg="transparent">
        <Heading
          color="brand.gray.900"
          fontSize={{ base: "1.8rem", xl: "2.5rem" }}
          w={{ base: "100%", md: "55%" }}
        >
          Authentication that feels like its from the future.
        </Heading>

        <SimpleGrid
          columns={{ base: 1, lg: 3 }}
          mt="2rem"
          spacing="2rem"
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          {features.map((feature) => (
            <Feature
              key={feature.id}
              id={feature.id}
              icon={feature.icon}
              title={feature.title}
              body={feature.body}
              svg={feature.svg}
            />
          ))}
        </SimpleGrid>
      </MainContainer>
    </Box>
  );
};
