export const Key: React.FC<any> = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.2446" cy="9.74359" r="9.74359" fill="#4ED17E" />
      <path
        d="M14.3436 16.4372L24.8088 5.97205L26.6944 7.85767L24.8088 9.74329L28.1087 13.0431L26.2231 14.9287L22.9232 11.6289L21.0376 13.5145L23.866 16.343L21.9804 18.2286L19.152 15.4002L16.2292 18.3228C17.9441 20.9114 17.6613 24.4332 15.3807 26.7139C12.7772 29.3174 8.55612 29.3174 5.95263 26.7139C3.34912 24.1103 3.34912 19.8892 5.95263 17.2858C8.23324 15.0051 11.7551 14.7223 14.3436 16.4372ZM13.4951 24.8282C15.0572 23.2662 15.0572 20.7335 13.4951 19.1714C11.933 17.6092 9.40034 17.6092 7.83824 19.1714C6.27615 20.7335 6.27615 23.2662 7.83824 24.8282C9.40034 26.3903 11.933 26.3903 13.4951 24.8282Z"
        fill="black"
      />
    </svg>
  );
};
