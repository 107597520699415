import { Box, Heading, SimpleGrid } from "@chakra-ui/react";

import { MainContainer } from "../../layout";
import { Feature } from "../../components";
import { Card } from "../../assets/svg/Card";
import { Phone } from "../../assets/svg/Phone";
import { Brush } from "../../assets/svg/Brush";

export const Features = () => {
  const features = [
    {
      id: 1,
      icon: <Card />,
      title: "Mobile Payment",
      body: "Make everyday payment anywhere from your phone",
      svg: true,
    },
    {
      id: 2,
      icon: <Phone />,
      title: "Easy Setup",
      body: "Start making mobile payments anywhere in 2 minutes",
      svg: true,
    },
    {
      id: 3,
      icon: <Brush />,
      title: "Frictionless Payment",
      body: "Make payment in 3 seconds",
      svg: true,
    },
  ];

  return (
    <Box bg="white" pb="3rem" pt={{ base: 0, md: "64px" }}>
      <MainContainer bg="transparent">
        <Heading
          color="brand.gray.900"
          fontSize={{ base: "1.8rem", xl: "2.5rem" }}
          // mt="12px"
          w={{ base: "100%", md: "55%" }}
        >
          Payment that feels like its from the future.
        </Heading>

        {/* <Text fontSize={20} color="brand.gray.500" textAlign="center" px="30%">
        We are building a modern infrastructure for financial account
        connectivity to support open finance in Africa.
      </Text> */}

        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          mt="2rem"
          spacing="2rem"
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          {features.map((feature) => (
            <Feature
              key={feature.id}
              id={feature.id}
              icon={feature.icon}
              title={feature.title}
              body={feature.body}
              svg={feature.svg}
            />
          ))}
        </SimpleGrid>
      </MainContainer>
    </Box>
  );
};
