import axios from "axios";
import crypto from "crypto-js";

const API_URL = process.env.REACT_APP_API_URL;
// const TOKEN = process.env.REACT_APP_ACCESS_TOKEN;
const API_KEY = process.env.REACT_APP_API_KEY;

export const makePostRequest = async (data: any, url: string) => {
  try {
    const response = await axios.post(`${API_URL}/${url}`, data, {
      headers: {
        // Authorization: `Bearer ${TOKEN}`,
        "x-api-key": API_KEY,
        "ngrok-skip-browser-warning": "69420",
      },
    });

    return response.data;
  } catch (error: any) {
    console.error(error);
    // throw error.message || "An error occurred";
  }
};

export const encrypt = (
  payload: any,
  APP_REQ_IV_KEY: string,
  APP_REQ_AES_KEY: string
) => {
  const dataToBeEncrypted = JSON.stringify(payload);
  if (typeof dataToBeEncrypted !== "string") {
    console.error(
      "the encryption function takes only strings as arguments; objects must be stringified before passage"
    );
  }

  // Convert the keys to WordArray format
  const aesKey = crypto.enc.Utf8.parse(APP_REQ_AES_KEY);
  const iv = crypto.enc.Utf8.parse(APP_REQ_IV_KEY);

  // Encrypt the plaintext using AES-128-CBC
  const ciphertext = crypto.AES.encrypt(dataToBeEncrypted, aesKey, {
    iv: iv,
    mode: crypto.mode.CBC,
    padding: crypto.pad.Pkcs7,
  });

  return ciphertext.toString();
};

export const decrypt = (
  encryptedData: any,
  APP_REQ_IV_KEY: string,
  APP_REQ_AES_KEY: string
) => {
  if (typeof encryptedData !== "string") {
    console.error("Cypher.decrypt error: argument must be string");
  }

  // Convert the keys and encrypted text to WordArray format
  const aesKey = crypto.enc.Utf8.parse(APP_REQ_AES_KEY);
  const iv = crypto.enc.Utf8.parse(APP_REQ_IV_KEY);

  // Decrypt the ciphertext using AES-128-CBC
  const decipher = crypto.AES.decrypt(encryptedData, aesKey, {
    iv: iv,
    mode: crypto.mode.CBC,
  });

  // Convert the result to a string
  const decryptedText = decipher.toString(crypto.enc.Utf8);

  return JSON.parse(decryptedText);
};
