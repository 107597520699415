import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Heading,
  Text,
  Flex,
  Box,
  Button,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { ArrowBackIcon, CheckIcon } from "@chakra-ui/icons";

import { makePostRequest, encrypt, decrypt } from "../queries";

export type PendingAuthorizationProps = {
  email: string;
  amount: string;
  reference: string | null;
  authorizationType: string | null;
  type?: string | null;
  step?: string | null;
};

const ContainerStyle = {
  borderRadius: "16px",
  borderWidth: "1px",
  borderColor: "brand.primary.50",
  bg: "#F9F9F9",
};

const HeaderStyle = {
  fontSize: "18px",
  color: "brand.primary.600",
  fontWeight: 600,
  textAlign: "center",
  px: "20%",
  mt: "48px",
};

export const PendingAuthorization: React.FC<PendingAuthorizationProps> = ({
  email,
  amount,
  reference,
  authorizationType,
  type,
  step,
}) => {
  const navigate = useNavigate();

  const toast = useToast();

  const ivKey: any = process.env.REACT_APP_REQ_IV_KEY;
  const aesKey: any = process.env.REACT_APP_REQ_AES_KEY;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const verifyTransaction = async () => {
    setIsLoading(true);

    const encryptedData = encrypt(
      {
        authorization_reference: reference,
        email,
        authorization_type: authorizationType,
      },
      ivKey,
      aesKey
    );

    makePostRequest(
      { data: encryptedData },
      "request/merchant/authorization/verification"
    )
      .then((res) => {
        console.log(res);
        const decryptedResponse =
          res?.data && decrypt(res?.data, ivKey, aesKey);

        console.log(decryptedResponse);

        if (res.message === "Authentication verification successful") {
          const dataString = JSON.stringify(decryptedResponse);
          const encodedData = encodeURIComponent(dataString);

          navigate(
            `/demo/authorization?authorizationType=${authorizationType}&amount=${amount}&status=success&data=${encodedData}`
          );
        } else if (
          res.message === "Failed Authentication" ||
          res.message === "Authentication rejected"
        ) {
          toast({
            title: `Error`,
            description: res.message,
            status: "error",
            duration: 4000,
            isClosable: true,
          });

          navigate(
            `/demo/authorization?authorizationType=${authorizationType}&type=${type}`
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    // Start the interval when the component mounts
    const intervalId = setInterval(verifyTransaction, 7000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={ContainerStyle} px={{ base: "16px", md: "48px" }} py="48px">
      <ArrowBackIcon
        boxSize={"24px"}
        color={"black"}
        mb="24px"
        cursor={"pointer"}
        onClick={() =>
          navigate(
            `/demo/authorization?authorizationType=${authorizationType}&type=${type}`
          )
        }
      />

      {type === "existing" ? (
        <Flex align="center" gap="8px" mb="72px">
          <Flex
            align="center"
            justify="center"
            bg="brand.success.600"
            w="20px"
            h="20px"
            borderRadius="10px"
          >
            <CheckIcon boxSize={11} color={"white"} />
          </Flex>

          <Text fontSize={11} color="brand.success.600">
            Initiate
          </Text>

          <Box w="30px" h="1px" bg="brand.success.600" />

          <Flex
            align="center"
            justify="center"
            borderWidth="1px"
            borderColor="brand.secondary.400"
            w="20px"
            h="20px"
            borderRadius="10px"
          >
            <Text color="brand.secondary.400" fontSize={11} fontWeight={600}>
              2
            </Text>
          </Flex>

          <Text fontSize={11} color="brand.secondary.500">
            Authorise
          </Text>

          <Box w="30px" h="1px" bg="brand.secondary.500" />

          <Flex
            align="center"
            justify="center"
            borderWidth="1px"
            borderColor="brand.secondary.400"
            w="20px"
            h="20px"
            borderRadius="10px"
          >
            <Text color="brand.secondary.400" fontSize={11} fontWeight={600}>
              3
            </Text>
          </Flex>

          <Text fontSize={11} color="brand.secondary.500">
            Success
          </Text>
        </Flex>
      ) : (
        <Flex align="center" gap="8px" mb="72px">
          <Flex
            align="center"
            justify="center"
            bg="brand.success.600"
            w={{ base: "16px", md: "20px" }}
            h={{ base: "16px", md: "20px" }}
            borderRadius="10px"
          >
            <CheckIcon boxSize={{ base: "9px", md: "11px" }} color={"white"} />
          </Flex>

          <Text
            fontSize={{ base: "9px", md: "11px" }}
            color="brand.success.600"
          >
            Download
          </Text>

          <Box w="30px" h="1px" bg="brand.success.600" />

          <Flex
            align="center"
            justify="center"
            bg="brand.success.600"
            w={{ base: "16px", md: "20px" }}
            h={{ base: "16px", md: "20px" }}
            borderRadius="10px"
          >
            <CheckIcon boxSize={{ base: "9px", md: "11px" }} color={"white"} />
          </Flex>

          <Text
            fontSize={{ base: "9px", md: "11px" }}
            color="brand.success.600"
          >
            Initiate
          </Text>

          <Box w="30px" h="1px" bg="brand.success.600" />

          <Flex
            align="center"
            justify="center"
            bg="brand.primary.500"
            w={{ base: "16px", md: "20px" }}
            h={{ base: "16px", md: "20px" }}
            borderRadius="10px"
          >
            <Text
              color="white"
              fontSize={{ base: "9px", md: "11px" }}
              fontWeight={600}
            >
              3
            </Text>
          </Flex>

          <Text
            fontSize={{ base: "9px", md: "11px" }}
            color="brand.primary.500"
          >
            Authorise
          </Text>

          <Box w="30px" h="1px" bg="brand.primary.500" />

          <Flex
            align="center"
            justify="center"
            borderWidth="1px"
            borderColor="brand.secondary.400"
            w={{ base: "16px", md: "20px" }}
            h={{ base: "16px", md: "20px" }}
            borderRadius="10px"
          >
            <Text
              color="brand.secondary.400"
              fontSize={{ base: "9px", md: "11px" }}
              fontWeight={600}
            >
              4
            </Text>
          </Flex>

          <Text
            fontSize={{ base: "9px", md: "11px" }}
            color="brand.secondary.500"
          >
            Success
          </Text>
        </Flex>
      )}

      <Flex justify="center">
        <Spinner
          thickness="6px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand.primary.500"
          size="xl"
        />
      </Flex>

      <Heading sx={HeaderStyle}>
        Authentication Notification has been sent to your CoAuth App
      </Heading>

      <Button
        mt="100px"
        size="lg"
        variant={"ghost"}
        color="brand.primary.500"
        borderWidth={"1px"}
        borderColor={"brand.primary.500"}
        w="100%"
        onClick={() =>
          navigate(
            `/demo/authorization?authorizationType=${authorizationType}&type=${type}`
          )
        }
        isLoading={isLoading}
      >
        I didn’t get a notification
      </Button>

      <Button
        mt="8px"
        size="lg"
        bg="brand.primary.500"
        color="white"
        _hover={{ bg: "#333" }}
        w="100%"
        onClick={() => verifyTransaction()}
        isLoading={isLoading}
      >
        I have authorized the transaction
      </Button>
    </Box>
  );
};
