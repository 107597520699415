import { useNavigate } from "react-router-dom";
import {
  Heading,
  Text,
  Flex,
  Box,
  Image,
  Button,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon, CheckIcon } from "@chakra-ui/icons";

import AppStore from "../assets/images/appStore.png";
import PlayStore from "../assets/images/playStore.png";

export type NewPaymentProps = {
  authorizationType: string | null;
};

const ContainerStyle = {
  borderRadius: "16px",
  borderWidth: "1px",
  borderColor: "brand.primary.50",
  bg: "#F9F9F9",
};

const HeaderStyle = {
  color: "brand.gray.1",
};

const todo = [
  "Entering your details",
  "Verifying your email",
  "Enabling biometrics",
  "Enabling push notification",
  "Enabling access to your location",
];

export const NewPayment: React.FC<NewPaymentProps> = ({
  authorizationType,
}) => {
  const navigate = useNavigate();

  return (
    <Box sx={ContainerStyle} px={{ base: "16px", md: "48px" }} py="48px">
      <ArrowBackIcon
        boxSize={"24px"}
        color={"black"}
        mb="24px"
        cursor={"pointer"}
        onClick={() =>
          navigate(`/demo/authorization?authorizationType=${authorizationType}`)
        }
      />

      <Flex align="center" gap="4px">
        <Flex
          align="center"
          justify="center"
          bg="brand.success.600"
          w={{ base: "16px", md: "20px" }}
          h={{ base: "16px", md: "20px" }}
          borderRadius="10px"
        >
          <CheckIcon boxSize={{ base: "9px", md: "11px" }} color={"white"} />
        </Flex>

        <Text fontSize={{ base: 9, md: 11 }} color="brand.success.600">
          Download
        </Text>

        <Box w="30px" h="1px" bg="brand.success.600" />

        <Flex
          align="center"
          justify="center"
          borderWidth="1px"
          borderColor="brand.secondary.400"
          w={{ base: "16px", md: "20px" }}
          h={{ base: "16px", md: "20px" }}
          borderRadius="10px"
        >
          <Text
            color="brand.secondary.400"
            fontSize={{ base: 9, md: 11 }}
            fontWeight={600}
          >
            2
          </Text>
        </Flex>

        <Text fontSize={{ base: 9, md: 11 }} color="brand.secondary.500">
          Initiate
        </Text>

        <Box w="30px" h="1px" bg="brand.secondary.500" />

        <Flex
          align="center"
          justify="center"
          borderWidth="1px"
          borderColor="brand.secondary.400"
          w={{ base: "16px", md: "20px" }}
          h={{ base: "16px", md: "20px" }}
          borderRadius="10px"
        >
          <Text
            color="brand.secondary.400"
            fontSize={{ base: 9, md: 11 }}
            fontWeight={600}
          >
            3
          </Text>
        </Flex>

        <Text fontSize={{ base: 9, md: 11 }} color="brand.secondary.500">
          Authorise
        </Text>

        <Box w="30px" h="1px" bg="brand.secondary.500" />

        <Flex
          align="center"
          justify="center"
          borderWidth="1px"
          borderColor="brand.secondary.400"
          w={{ base: "16px", md: "20px" }}
          h={{ base: "16px", md: "20px" }}
          borderRadius="10px"
        >
          <Text
            color="brand.secondary.400"
            fontSize={{ base: 9, md: 11 }}
            fontWeight={600}
          >
            4
          </Text>
        </Flex>

        <Text fontSize={{ base: 9, md: 11 }} color="brand.secondary.500">
          Success
        </Text>
      </Flex>

      <Heading
        sx={HeaderStyle}
        mt="32px"
        fontSize={{ base: "20px", md: "23px" }}
        textTransform={"capitalize"}
      >
        CoAuth {authorizationType} Demo
      </Heading>

      <Text fontSize={14} color="brand.secondary.700" mt="24px" mb="12px">
        To get started, download the CoAuth Wallet from your app store
      </Text>

      <HStack mt="8px">
        <a
          href={`${process.env.REACT_APP_APP_STORE}`}
          target="_blank"
          rel="noreferrer"
        >
          <Image src={AppStore} alt="" w="148px" h="44px" />
        </a>

        <a
          href={`${process.env.REACT_APP_PLAY_STORE}`}
          target="_blank"
          rel="noreferrer"
        >
          <Image src={PlayStore} alt="" w="148px" h="44px" />
        </a>
      </HStack>

      <Box
        borderWidth={"1px"}
        borderColor={"brand.success.500"}
        my="32px"
        bg="brand.success.50"
        borderRadius={"8px"}
        p="16px"
      >
        <Text
          fontWeight={600}
          fontSize={14}
          color="brand.success.700"
          mb="16px"
        >
          Ensure you complete your onboarding by:
        </Text>

        {todo.map((_todo, index) => (
          <Flex align="center" mt="16px" gap="8px" key={index}>
            <Flex
              w="24px"
              h="24px"
              borderRadius="12px"
              bg="brand.success.700"
              align="center"
              justify="center"
            >
              <CheckIcon color="white" boxSize="12px" />
            </Flex>

            <Text fontSize={"14px"} color="brand.success.700">
              {_todo}
            </Text>
          </Flex>
        ))}
      </Box>

      <Button
        size="lg"
        bg="brand.primary.500"
        color="white"
        _hover={{ bg: "#333" }}
        w="100%"
        onClick={() =>
          navigate(
            `/demo/authorization?authorizationType=${authorizationType}&type=new&step=initiate`
          )
        }
      >
        I’ve completed the app onboarding
      </Button>
    </Box>
  );
};
