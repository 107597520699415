export const EncryptionSvg: React.FC<any> = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.2441" cy="9.74359" r="9.74359" fill="#4ED17E" />
      <path
        d="M29.3332 12.6667H26.6665V10H15.4476L12.7809 7.33335H5.33317V26H15.2624C15.745 27.0555 16.5046 27.9804 17.4817 28.6667H3.99984C3.26346 28.6667 2.6665 28.0698 2.6665 27.3334V6.00002C2.6665 5.26365 3.26346 4.66669 3.99984 4.66669H13.8854L16.5521 7.33335H27.9998C28.7362 7.33335 29.3332 7.93031 29.3332 8.66669V12.6667ZM17.3332 15.3334H29.3332V23.2654C29.3332 24.5863 28.6648 25.8198 27.552 26.5526L23.3332 29.3304L19.1144 26.5526C18.0016 25.8198 17.3332 24.5863 17.3332 23.2654V15.3334ZM19.9998 23.2654C19.9998 23.687 20.2153 24.0846 20.5809 24.3254L23.3332 26.1376L26.0854 24.3254C26.451 24.0846 26.6665 23.687 26.6665 23.2654V18H19.9998V23.2654Z"
        fill="black"
      />
    </svg>
  );
};
