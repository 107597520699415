export const Phone: React.FC<any> = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.2441" cy="9.74359" r="9.74359" fill="#4ED17E" />
      <path
        d="M9.33317 6.00004V27.3334H22.6665V6.00004H9.33317ZM7.99984 3.33337H23.9998C24.7362 3.33337 25.3332 3.93033 25.3332 4.66671V28.6667C25.3332 29.4031 24.7362 30 23.9998 30H7.99984C7.26346 30 6.6665 29.4031 6.6665 28.6667V4.66671C6.6665 3.93033 7.26346 3.33337 7.99984 3.33337ZM15.9998 23.3334C16.7362 23.3334 17.3332 23.9303 17.3332 24.6667C17.3332 25.4031 16.7362 26 15.9998 26C15.2634 26 14.6665 25.4031 14.6665 24.6667C14.6665 23.9303 15.2634 23.3334 15.9998 23.3334Z"
        fill="black"
      />
    </svg>
  );
};
