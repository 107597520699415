import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Heading,
  UnorderedList,
  ListItem,
  SimpleGrid,
} from "@chakra-ui/react";

import { MainContainer } from "../../layout";
import {
  ChooseAmount,
  DemoSuccess,
  FollowSteps,
  NewPayment,
  PendingAuthorization,
} from "../../components";

export const Checkout = () => {
  const [renderComponent, setRenderComponent] = useState<any>();

  const search = useLocation().search;
  const authorizationType: string | null = new URLSearchParams(search).get(
    "authorizationType"
  );
  const type: string | null = new URLSearchParams(search).get("type");
  const email: string | null = new URLSearchParams(search).get("email");
  const amount: string | null = new URLSearchParams(search).get("amount");
  const status: string | null = new URLSearchParams(search).get("status");
  const reference: string | null = new URLSearchParams(search).get("reference");
  const data: string | null = new URLSearchParams(search).get("data");
  const step: string | null = new URLSearchParams(search).get("step");

  useEffect(() => {
    if (status === "success" && data)
      setRenderComponent(
        <DemoSuccess
          amount={amount}
          authorizationType={authorizationType}
          data={data}
        />
      );
    else if (email && amount)
      type &&
        setRenderComponent(
          <PendingAuthorization
            email={email}
            amount={amount}
            reference={reference}
            authorizationType={authorizationType}
            type={type}
          />
        );
    else if (type === "existing")
      setRenderComponent(
        <ChooseAmount
          authorizationType={authorizationType}
          type={type}
          step={null}
        />
      );
    else if (type === "new") {
      if (step === "initiate")
        setRenderComponent(
          <ChooseAmount
            authorizationType={authorizationType}
            type={type}
            step={step}
          />
        );
      else if (step === "authorise")
        email &&
          amount &&
          setRenderComponent(
            <PendingAuthorization
              email={email}
              amount={amount}
              reference={reference}
              authorizationType={authorizationType}
              type={type}
              step={step}
            />
          );
      else
        setRenderComponent(
          <NewPayment authorizationType={authorizationType} />
        );
    } else
      setRenderComponent(<FollowSteps authorizationType={authorizationType} />);
  }, [type, email, amount, status, reference, authorizationType, data, step]);

  return (
    <Box py={{ base: "48px", md: "96px" }} mb="90px">
      <MainContainer bg="transparent">
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          gap={{ base: "64px", lg: "116px" }}
        >
          <Box>
            <Heading
              color="brand.primary.500"
              fontSize={{ base: "29px", xl: "32px" }}
            >
              Get to explore the CoAuth Checkout
            </Heading>

            <UnorderedList color="brand.primary.500" mt="15px" spacing={"20px"}>
              <ListItem fontSize={{ base: "16px", md: "20px" }}>
                Make a quick and secure donation with the CoAuth App - no fees
                attached.
              </ListItem>

              <ListItem fontSize={{ base: "16px", md: "20px" }}>
                Please note that this is a real transaction, and that your
                account will be debited.
              </ListItem>
            </UnorderedList>
          </Box>

          {renderComponent}
        </SimpleGrid>
      </MainContainer>
    </Box>
  );
};
