import { Flex, Heading, Image } from "@chakra-ui/react";

import IconImage from "../../src/assets/images/icon.png";

export type LogoProps = {
  onPress?: () => void;
};

export const Logo: React.FC<LogoProps> = ({ onPress }) => {
  return (
    <Flex align="center" onClick={onPress} cursor="pointer">
      <Heading fontWeight={700} fontSize={22} mr="6px" color="brand.primary.500">
        CoAuth
      </Heading>

      <Image src={IconImage} w={"20px"} h="20px" mt="-10px" />
      {/* <Box w="14px" h="14px" bg="black" color="brand.primary.500" /> */}
    </Flex>
  );
};
