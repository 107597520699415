import { useNavigate } from "react-router-dom";
import { Heading, Text, Flex, Box, Avatar } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";

export type FollowStepsProps = {
  authorizationType: string | null;
};

export const FollowSteps: React.FC<FollowStepsProps> = ({
  authorizationType,
}) => {
  const navigate = useNavigate();

  const ContainerStyle = {
    borderRadius: "16px",
    borderWidth: "1px",
    borderColor: "brand.primary.50",
    bg: "#F9F9F9",
    py: "48px",
  };

  const HeaderStyle = {
    color: "brand.primary.600",
    fontWeight: 600,
  };

  const BigHeaderStyle = {
    color: "brand.gray.1",
  };

  const TextStyle = {
    color: "brand.gray.3",
    mt: "4px",
  };

  const LineStyle = {
    w: "100%",
    h: "1px",
    bg: "brand.neutral.50",
  };

  return (
    <Box sx={ContainerStyle} px={{ base: "32px", md: "48px" }}>
      <Heading sx={BigHeaderStyle} fontSize={{ base: "23px", md: "26px" }}>
        Follow steps to experience CoAuth
      </Heading>

      <Flex
        align={"center"}
        justify={"space-between"}
        mt="32px"
        cursor="pointer"
        onClick={() =>
          navigate(
            `/demo/authorization?authorizationType=${authorizationType}&type=existing`
          )
        }
      >
        <Flex align={"center"} gap={"16px"}>
          <Avatar src="./broken.png" bg="black" />

          <Box>
            <Heading sx={HeaderStyle} fontSize={{ base: "14px", md: "18px" }}>
              Already have an account?
            </Heading>
            <Text sx={TextStyle} fontSize={{ base: "11px", md: "13px" }}>
              Ensure you are logged into the CoAuth App
            </Text>
          </Box>
        </Flex>

        <ChevronRightIcon color={"brand.gray.4"} boxSize={"24px"} />
      </Flex>

      <Box sx={LineStyle} my={{ base: "16px", md: "32px" }} />

      <Flex
        align={"center"}
        justify={"space-between"}
        cursor="pointer"
        onClick={() =>
          navigate(
            `/demo/authorization?authorizationType=${authorizationType}&type=new`
          )
        }
      >
        <Flex align={"center"} gap={"16px"}>
          <Avatar src="./broken.png" bg="black" />

          <Box>
            <Heading sx={HeaderStyle} fontSize={{ base: "14px", md: "18px" }}>
              New to CoAuth?
            </Heading>
            <Text sx={TextStyle} fontSize={{ base: "11px", md: "13px" }}>
              Get the CoAuth App & complete the onboarding process
            </Text>
          </Box>
        </Flex>

        <ChevronRightIcon color={"brand.gray.4"} boxSize={"24px"} />
      </Flex>
    </Box>
  );
};
