import { Box, Heading, Text, Flex, Image, SimpleGrid } from "@chakra-ui/react";

import { MainContainer } from "../../layout";
import EncryptionImage from "../../assets/images/encryption.png";
import { EncryptionSvg } from "../../assets/svg/Encryption";

export const Encryption = () => {
  return (
    <MainContainer bg="transparent">
      <Box
        bg="brand.secondary.50"
        px={{ base: "3rem", md: "4rem" }}
        py={{ base: "3rem", md: 0 }}
        borderRadius={"2rem"}
        mt="3rem"
        mb="2rem"
        as="section"
      >
        <SimpleGrid
          gap={{ base: 0, md: "4rem" }}
          columns={{ base: 1, md: 2 }}
          alignItems={"center"}
        >
          <Box>
            <Flex
              borderRadius="1rem"
              bg="white"
              w="4rem"
              h="4rem"
              align="center"
              justify="center"
            >
              <EncryptionSvg />
            </Flex>

            <Heading
              color="brand.gray.900"
              fontSize={{ base: "1.8rem", xl: "2rem" }}
              fontWeight={700}
              mt="0.8rem"
              mb="1rem"
            >
              Multi-layered Protection
            </Heading>

            <Text
              color="brand.gray.500"
              fontSize={{ base: "1rem", xl: "1.1rem" }}
            >
              Built with your utmost security, privacy and trust in mind, and
              with bank grade security.
            </Text>
          </Box>

          <Flex justifyContent={"center"}>
            <Image
              src={EncryptionImage}
              alt=""
              h={{ base: "21rem", md: "27rem" }}
            />
          </Flex>
        </SimpleGrid>
      </Box>
    </MainContainer>
  );
};
