import { useNavigate } from "react-router-dom";
import { Box, Heading, Text, Button, SimpleGrid, Flex } from "@chakra-ui/react";
import { Card } from "../../assets/svg/Card";
import { User } from "../../assets/svg/User";

import { MainContainer } from "../../layout";

export const productStyles = {
  borderRadius: "16px",
  bg: "#F9F9F9",
  border: "1px solid brand.gray.200",
  // boxShadow:
  //   "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
  px: "32px",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const Products = () => {
  const navigate = useNavigate();

  const features = [
    {
      id: 1,
      icon: <Card />,
      title: "Payments",
      body: "Experience how we connect to your financial account to make payment",
      link: "payment",
    },
    {
      id: 2,
      icon: <User />,
      title: "Financial Data",
      body: "Experience how we connect to your financial account to retrieve data",
      link: "data",
    },
  ];

  return (
    <Box py={{ base: "48px", md: "96px" }} mb="90px" as="section">
      <MainContainer bg="transparent">
        <Heading
          color="brand.primary.500"
          fontSize={{ base: "2rem", xl: "2.5rem" }}
          textAlign="center"
        >
          Experience the CoAuth Products
        </Heading>

        <Text
          color="brand.primary.500"
          fontSize={{ base: "1rem", xl: "1.2rem" }}
          mt="8px"
          textAlign="center"
        >
          See our unique authentication flow for yourself
        </Text>

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          gap="32px"
          alignItems="center"
          mt="32px"
        >
          {features.map((feature) => (
            <Box sx={productStyles} key={feature.id}>
              <Flex
                key={feature.id}
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
                borderRadius={"32px"}
                h="100%"
                pt={{ base: "30px", md: "64px" }}
              >
                <Flex
                  borderRadius="16px"
                  bg="white"
                  w="64px"
                  h="64px"
                  align="center"
                  justify="center"
                >
                  {feature.icon}
                </Flex>

                <Heading
                  color="brand.gray.900"
                  fontSize={{ base: "29px", xl: "32px" }}
                  my="16px"
                >
                  {feature.title}
                </Heading>

                <Text
                  fontSize={"16px"}
                  color="brand.gray.500"
                  px="12.5%"
                  textAlign={"center"}
                >
                  {feature.body}
                </Text>
              </Flex>

              <Button
                mt={{ base: "32px", md: "64px" }}
                bg="brand.primary.500"
                color="white"
                _hover={{ bg: "brand.primary.500" }}
                w="100%"
                mb="32px"
                size={"lg"}
                onClick={() =>
                  navigate(
                    `/demo/authorization?authorizationType=${feature.link}`
                  )
                }
              >
                Launch Demo
              </Button>
            </Box>
          ))}
        </SimpleGrid>
      </MainContainer>
    </Box>
  );
};
