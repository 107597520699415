export const colors = {
  brand: {
    primary: {
      50: "#E7E7E7",
      100: "#B3B3B4",
      200: "#1C202B",
      300: "#5B5C5D",
      500: "#0A0B0D",
      600: "#090A0C",
      700: "#070809"
    },
    secondary: {
      50: "#F0F2F3",
      100: "#D1D5DA",
      200: "#BBC1C8",
      400: "#8993A0",
      500: "#6B7888",
    },
    white: "#FFFFFF",
    gray: {
      1: "#303237",
      3: "#7E8494",
      4: "#BDC0CE",
      50: "#F9FAFB",
      100: "#F2F4F7",
      200: "#EAECF0",
      300: "#D0D5DD",
      400: "#98A2B3",
      500: "#667085",
      700: "rgba(52, 64, 84, 1)",
      900: "#101828",
    },
    neutral: {
      50: "#E6E6E6",
    },
    success: {
      50: "#EBF3FE",
      500: "#3B82F6",
      600: "#1FB356",
      700: "#2A5CAF"
    },
  },
};
