export const Wallet: React.FC<any> = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.2441" cy="9.74359" r="9.74359" fill="#4ED17E" />
      <path
        d="M26.6734 10V7.33335H5.34001V26H26.6734V23.3334H16.0067C15.2703 23.3334 14.6734 22.7364 14.6734 22V11.3334C14.6734 10.597 15.2703 10 16.0067 10H26.6734ZM4.00667 4.66669H28.0067C28.7431 4.66669 29.34 5.26365 29.34 6.00002V27.3334C29.34 28.0698 28.7431 28.6667 28.0067 28.6667H4.00667C3.2703 28.6667 2.67334 28.0698 2.67334 27.3334V6.00002C2.67334 5.26365 3.2703 4.66669 4.00667 4.66669ZM17.34 12.6667V20.6667H26.6734V12.6667H17.34ZM20.0067 15.3334H24.0067V18H20.0067V15.3334Z"
        fill="black"
      />
    </svg>
  );
};
