export const Code: React.FC<any> = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.2446" cy="9.74359" r="9.74359" fill="#4ED17E" />
      <path
        d="M4.00033 4.66675H28.0003C28.7367 4.66675 29.3337 5.26371 29.3337 6.00008V27.3334C29.3337 28.0698 28.7367 28.6667 28.0003 28.6667H4.00033C3.26395 28.6667 2.66699 28.0698 2.66699 27.3334V6.00008C2.66699 5.26371 3.26395 4.66675 4.00033 4.66675ZM5.33366 7.33341V26.0001H26.667V7.33341H5.33366ZM16.0003 20.6667H24.0003V23.3334H16.0003V20.6667ZM11.5561 16.6667L7.78488 12.8955L9.67051 11.0099L15.3274 16.6667L9.67051 22.3235L7.78488 20.4379L11.5561 16.6667Z"
        fill="black"
      />
    </svg>
  );
};
