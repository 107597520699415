import {
  Text,
  Flex,
  Button,
  HStack,
  Box,
  Collapse,
  useDisclosure,
} from "@chakra-ui/react";
// import { ChevronDownIcon } from "@chakra-ui/icons";
import { useNavigate, useLocation } from "react-router-dom";

import { MainContainer } from "../layout";
import { Logo } from "./Logo";

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isOpen, onToggle } = useDisclosure();

  const active = {
    color: "black",
    textDecoration: "underline",
    textUnderlineOffset: "3px",
  };

  const inactive = {
    color: "brand.gray.500",
    textDecoration: "none",
  };

  return (
    <MainContainer bg="transparent">
      <Flex
        pos="sticky"
        align="center"
        justifyContent="space-between"
        bg="transparent"
        zIndex={100}
        h="5rem"
      >
        <HStack>
          <Logo onPress={() => navigate("/")} />

          <Text
            fontSize={"1rem"}
            sx={location.pathname === "/consumers" ? active : inactive}
            ml="3rem"
            mr="2rem"
            cursor={"pointer"}
            onClick={() => navigate("/consumers")}
            display={{ base: "none", md: "block" }}
            _hover={{ textDecoration: "underline", textUnderlineOffset: "3px" }}
          >
            Consumers
          </Text>

          <Text
            fontSize={16}
            sx={location.pathname === "/" ? active : inactive}
            mr="2rem"
            cursor={"pointer"}
            onClick={() => navigate("/")}
            display={{ base: "none", md: "block" }}
            _hover={{ textDecoration: "underline", textUnderlineOffset: "3px" }}
          >
            Business
          </Text>

          {/* <Text
            fontSize={16}
            color="brand.gray.500"
            cursor={"pointer"}
            display={{ base: "none", md: "block" }}
          >
            FAQs
          </Text> */}
        </HStack>

        <HStack>
          <Button
            variant="ghost"
            color="brand.gray.500"
            display={{ base: "none", md: "block" }}
          >
            <a
              href={`${process.env.REACT_APP_DASHBOARD}/login`}
              target="_blank"
              rel="noreferrer"
            >
              Log in
            </a>
          </Button>

          <Button
            bg="brand.primary.500"
            color="white"
            _hover={{ bg: "brand.primary.500" }}
            display={{ base: "none", md: "block" }}
          >
            <a
              href={`${process.env.REACT_APP_DASHBOARD}/signup`}
              target="_blank"
              rel="noreferrer"
            >
              Sign up
            </a>
          </Button>

          <Box
            display={{ base: "block", md: "none" }}
            onClick={onToggle}
            cursor={"pointer"}
          >
            {[1, 2, 3].map((num) => (
              <Box
                key={num}
                h="3px"
                w="24px"
                bg="brand.gray.700"
                my="4px"
                borderRadius={"3px"}
              />
            ))}
          </Box>
        </HStack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Box mt="4px" display={{ base: "block", md: "none" }}>
          <Text
            fontSize={"1rem"}
            sx={location.pathname === "/consumers" ? active : inactive}
            mb="2rem"
            cursor={"pointer"}
            onClick={() => navigate("/consumers")}
            _hover={{ textDecoration: "underline", textUnderlineOffset: "3px" }}
          >
            Consumers
          </Text>

          <Text
            fontSize={"1rem"}
            sx={location.pathname === "/" ? active : inactive}
            mb="2rem"
            cursor={"pointer"}
            onClick={() => navigate("/")}
            _hover={{ textDecoration: "underline", textUnderlineOffset: "3px" }}
          >
            Business
          </Text>

          {/* <Text
            fontSize={16}
            color="brand.gray.500"
            cursor={"pointer"}
            mb="32px"
          >
            FAQs
          </Text> */}

          <Box mb="2rem">
            <Button variant="ghost" color="brand.gray.500">
              <a
                href={`${process.env.REACT_APP_DASHBOARD}/login`}
                target="_blank"
                rel="noreferrer"
              >
                Log in
              </a>
            </Button>
          </Box>

          <Button
            bg="brand.primary.500"
            color="white"
            _hover={{ bg: "brand.primary.500" }}
          >
            <a
              href={`${process.env.REACT_APP_DASHBOARD}/signup`}
              target="_blank"
              rel="noreferrer"
            >
              Sign up
            </a>
          </Button>
        </Box>
      </Collapse>
    </MainContainer>
  );
};
