import {
  Box,
  Heading,
  Flex,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import { MainContainer } from "../../layout";

const lists = [
  {
    id: 1,
    header: "Personal Information",
    subtitle: "We may collect the following types of personal information:",
    list: [
      {
        id: 1,
        value:
          "Contact Information: Your name, email address, phone number, and mailing address.",
      },
      {
        id: 2,
        value:
          "Financial Information: Information related to your financial accounts, transactions, and history.",
      },
      {
        id: 3,
        value:
          "Identity Verification: Government-issued identification documents and related information for verification purposes.",
      },
      {
        id: 4,
        value:
          "Device and Usage Information: Information about your device, including IP address, browser type, and operating system, as well as how you interact with our app and website.",
      },
      {
        id: 5,
        value:
          "Location Information: We may collect your location data with your consent to provide location-based services.",
      },
    ],
  },
  {
    id: 2,
    header: "How We Use Your Information",
    subtitle:
      "We may use your personal information for the following purposes:",
    list: [
      {
        id: 1,
        value:
          "To Provide Services: To offer our fintech services, including processing transactions, managing accounts, and providing customer support.",
      },
      {
        id: 2,
        value:
          "To Verify Identity: To verify your identity for security and regulatory compliance purposes.",
      },
      {
        id: 3,
        value:
          "To Improve Our Services: To analyze and enhance the user experience, troubleshoot issues, and develop new features.",
      },
      {
        id: 4,
        value:
          "To Communicate: To send you important updates, account notifications, and marketing communications (if you have opted in).",
      },
      {
        id: 5,
        value:
          "To Comply with Legal Obligations: To meet our legal and regulatory obligations, including anti-money laundering and fraud prevention.",
      },
    ],
  },
  {
    id: 3,
    header: "Information Sharing",
    subtitle:
      "We may share your personal information with third parties in the following situations:",
    list: [
      {
        id: 1,
        value:
          "Service Providers: We may share data with trusted third-party service providers who assist us in delivering our services.",
      },
      {
        id: 2,
        value:
          "Legal Compliance: We may disclose information to comply with legal obligations or respond to lawful requests from government authorities.",
      },
      {
        id: 3,
        value:
          "Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.",
      },
    ],
  },
  {
    id: 4,
    header: "Security Measures",
    subtitle:
      "We implement robust security measures to protect your personal information, including encryption, access controls, and regular security assessments.",
    list: [],
  },
  {
    id: 5,
    header: "Your Rights",
    subtitle:
      "You have certain rights regarding your personal information, including the right to access, correct, or delete your data. You can exercise these rights by contacting us at [contact email/phone]. For more information, please review our [link to Data Subject Rights page].",
    list: [],
  },
  {
    id: 6,
    header: "Children's Privacy",
    subtitle:
      "Our services are not intended for children under the age of 18. We do not knowingly collect or maintain information from individuals under 18 years of age.",
    list: [],
  },
  {
    id: 7,
    header: "Changes to this Privacy Policy",
    subtitle:
      "We may update this Privacy Policy from time to time to reflect changes in our practices and services. We will notify you of any significant changes through the app or via email.",
    list: [],
  },
  {
    id: 6,
    header: "Contact Us",
    subtitle:
      "If you have any questions, concerns, or requests related to your privacy, please contact us at [contact email/phone].",
    list: [],
  },
];

export const PrivacyPage = () => {
  return (
    <MainContainer>
      <Flex my="96px" gap="58px" flexDir={{ base: "column", md: "row" }}>
        <Box>
          <Heading fontSize={16} color="brand.primary.500" mb="24px">
            ON THIS PAGE
          </Heading>

          <Text fontSize={16} color="brand.primary.500" mb="16px">
            Personal Information
          </Text>

          <Text fontSize={16} color="brand.gray.500" mb="16px">
            How We Use Your Information
          </Text>

          <Text fontSize={16} color="brand.gray.500" mb="16px">
            Information Sharing
          </Text>

          <Text fontSize={16} color="brand.gray.500" mb="16px">
            Security Measures
          </Text>

          <Text fontSize={16} color="brand.gray.500" mb="16px">
            Your Rights
          </Text>

          <Text fontSize={16} color="brand.gray.500" mb="16px">
            Children's Privacy
          </Text>

          <Text fontSize={16} color="brand.gray.500" mb="16px">
            Changes to this Privacy Policy
          </Text>

          <Text fontSize={16} color="brand.gray.500" mb="16px">
            Contact Us
          </Text>
        </Box>

        <Box flex={1}>
          <Text fontSize={16} color="brand.primary.500" mb="14px">
            Last Updated: 05 October 2023
          </Text>

          <Heading fontSize={32} color="brand.gray.900" mb="16px">
            Privacy Policy
          </Heading>

          <Text color="#000">
            Welcome to CoAuth ("we," "our," or "us"). Your privacy and security
            are of utmost importance to us, and we are committed to protecting
            your personal information. This Privacy Policy is designed to help
            you understand how we collect, use, disclose, and safeguard your
            information when you use our fintech services and visit our website.
          </Text>

          <Heading fontSize={26} color="#000" mt="20px">
            Information We Collect
          </Heading>

          {lists.map((menu) => (
            <Box key={menu.id}>
              <Heading fontSize={20} color="#000" mt="24px" mb="12px">
                {menu.header}
              </Heading>

              <Text color="#000" mb="12px">
                {menu.subtitle}
              </Text>

              <UnorderedList>
                {menu.list.map((item) => (
                  <ListItem key={item.id}>{item.value}</ListItem>
                ))}
              </UnorderedList>
            </Box>
          ))}
        </Box>
      </Flex>
    </MainContainer>
  );
};
