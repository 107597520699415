import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Heading,
  Text,
  Flex,
  Box,
  Input,
  FormControl,
  FormLabel,
  Button,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { ArrowBackIcon, CheckIcon } from "@chakra-ui/icons";

import { makePostRequest, encrypt, decrypt } from "../queries";

export type ChooseAmountProps = {
  authorizationType: string | null;
  type: string | null;
  step?: string | null;
};

const ContainerStyle = {
  borderRadius: "16px",
  borderWidth: "1px",
  borderColor: "brand.primary.50",
  bg: "#F9F9F9",
};

const HeaderStyle = {
  color: "brand.gray.1",
};

const TextStyle = {
  textAlign: "center",
  fontSize: 16,
};

const AmountContainer = {
  minWidth: "95px",
  p: "10px",
  borderRadius: "8px",
  borderWidth: "1px",
  borderColor: "brand.secondary.200",
  my: "8px",
  cursor: "pointer",
};

export const ChooseAmount: React.FC<ChooseAmountProps> = ({
  authorizationType,
  type,
  step,
}) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [randomGenerated, setRandomGenerated] = useState<boolean>(false);

  const toast = useToast();

  const ivKey: any = process.env.REACT_APP_REQ_IV_KEY;
  const aesKey: any = process.env.REACT_APP_REQ_AES_KEY;

  const handleSubmit = async () => {
    setIsLoading(true);

    const encryptedData = encrypt(
      {
        amount,
        customer_email: email,
        authorization_type: authorizationType,
      },
      ivKey,
      aesKey
    );

    await makePostRequest(
      { data: encryptedData },
      "request/merchant/authorization"
    )
      .then((res) => {
        const decryptedResponse = decrypt(res?.data, ivKey, aesKey);

        // console.log(decryptedResponse);

        if (res)
          navigate(
            `/demo/authorization?authorizationType=${authorizationType}&type=${type}&email=${email}&amount=${amount}&reference=${
              decryptedResponse?.authentication_reference
            }&step=${step ? "authorise" : null}`
          );
      })
      .catch((err) => {
        toast({
          title: "An error occured",
          // description: err,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        console.error(err);
      });

    setIsLoading(false);
  };

  const generateRandomAmount = () => {
    setIsLoading(true);
    setInterval(() => setIsLoading(false), 1000);
    const min = 50;
    const max = 1000;
    const randomAmount = Math.random() * (max - min) + min;
    settingAmount(parseFloat(randomAmount.toFixed(2)), true);
    setRandomGenerated(true);
  };

  const settingAmount = (value: number, random: boolean) => {
    !random && setRandomGenerated(false);
    setAmount(value);
  };

  return (
    <Box sx={ContainerStyle} px={{ base: "16px", md: "48px" }} py="48px">
      <ArrowBackIcon
        boxSize={"24px"}
        color={"black"}
        mb="24px"
        cursor={"pointer"}
        onClick={() =>
          navigate(`/demo/authorization?authorizationType=${authorizationType}`)
        }
      />

      {type === "existing" ? (
        <Flex align="center" gap="8px">
          <Flex
            align="center"
            justify="center"
            bg="brand.primary.500"
            w="20px"
            h="20px"
            borderRadius="10px"
          >
            <Text color="white" fontSize={11} fontWeight={600}>
              1
            </Text>
          </Flex>

          <Text fontSize={11} color="brand.primary.500">
            Initiate
          </Text>

          <Box w="30px" h="1px" bg="brand.primary.500" />

          <Flex
            align="center"
            justify="center"
            borderWidth="1px"
            borderColor="brand.secondary.400"
            w="20px"
            h="20px"
            borderRadius="10px"
          >
            <Text color="brand.secondary.400" fontSize={11} fontWeight={600}>
              2
            </Text>
          </Flex>

          <Text fontSize={11} color="brand.secondary.500">
            Authorise
          </Text>

          <Box w="30px" h="1px" bg="brand.secondary.500" />

          <Flex
            align="center"
            justify="center"
            borderWidth="1px"
            borderColor="brand.secondary.400"
            w="20px"
            h="20px"
            borderRadius="10px"
          >
            <Text color="brand.secondary.400" fontSize={11} fontWeight={600}>
              3
            </Text>
          </Flex>

          <Text fontSize={11} color="brand.secondary.500">
            Success
          </Text>
        </Flex>
      ) : (
        <Flex align="center" gap="8px">
          <Flex
            align="center"
            justify="center"
            bg="brand.success.600"
            w={{ base: "16px", md: "20px" }}
            h={{ base: "16px", md: "20px" }}
            borderRadius="10px"
          >
            <CheckIcon boxSize={{ base: "9px", md: "11px" }} color={"white"} />
          </Flex>

          <Text
            fontSize={{ base: "9px", md: "11px" }}
            color="brand.success.600"
          >
            Download
          </Text>

          <Box w="30px" h="1px" bg="brand.success.600" />

          <Flex
            align="center"
            justify="center"
            bg="brand.primary.500"
            w={{ base: "16px", md: "20px" }}
            h={{ base: "16px", md: "20px" }}
            borderRadius="10px"
          >
            <Text
              color="white"
              fontSize={{ base: "9px", md: "11px" }}
              fontWeight={600}
            >
              2
            </Text>
          </Flex>

          <Text
            fontSize={{ base: "9px", md: "11px" }}
            color="brand.primary.500"
          >
            Initiate
          </Text>

          <Box w="30px" h="1px" bg="brand.primary.500" />

          <Flex
            align="center"
            justify="center"
            borderWidth="1px"
            borderColor="brand.secondary.400"
            w={{ base: "16px", md: "20px" }}
            h={{ base: "16px", md: "20px" }}
            borderRadius="10px"
          >
            <Text
              color="brand.secondary.400"
              fontSize={{ base: "9px", md: "11px" }}
              fontWeight={600}
            >
              3
            </Text>
          </Flex>

          <Text
            fontSize={{ base: "9px", md: "11px" }}
            color="brand.secondary.500"
          >
            Authorise
          </Text>

          <Box w="30px" h="1px" bg="brand.secondary.500" />

          <Flex
            align="center"
            justify="center"
            borderWidth="1px"
            borderColor="brand.secondary.400"
            w={{ base: "16px", md: "20px" }}
            h={{ base: "16px", md: "20px" }}
            borderRadius="10px"
          >
            <Text
              color="brand.secondary.400"
              fontSize={{ base: "9px", md: "11px" }}
              fontWeight={600}
            >
              4
            </Text>
          </Flex>

          <Text
            fontSize={{ base: "9px", md: "11px" }}
            color="brand.secondary.500"
          >
            Success
          </Text>
        </Flex>
      )}

      <Heading
        sx={HeaderStyle}
        fontSize={{ base: "20px", md: "23px" }}
        mt="32px"
        textTransform={"capitalize"}
      >
        CoAuth {authorizationType} Demo
      </Heading>

      <FormControl mt="18px" mb="32px">
        <FormLabel fontSize={13} color="brand.secondary.500">
          Email
        </FormLabel>
        <Input type="email" onChange={(e) => setEmail(e.target.value)} />
      </FormControl>

      {authorizationType === "payment" && (
        <>
          <Text fontSize={13} color="brand.secondary.500">
            Choose an amount
          </Text>

          <HStack gap="10px">
            <Box
              sx={AmountContainer}
              onClick={() => settingAmount(100, false)}
              bg={amount === 100 ? `brand.primary.500` : `brand.primary.50`}
            >
              <Text
                sx={TextStyle}
                color={amount === 100 ? `white` : `brand.primary.500`}
              >
                ₦ 100
              </Text>
            </Box>

            <Box
              sx={AmountContainer}
              onClick={() => settingAmount(1000, false)}
              bg={amount === 1000 ? `brand.primary.500` : `brand.primary.50`}
            >
              <Text
                sx={TextStyle}
                color={amount === 1000 ? `white` : `brand.primary.500`}
              >
                ₦ 1000
              </Text>
            </Box>

            <Box
              sx={AmountContainer}
              onClick={generateRandomAmount}
              bg={randomGenerated ? `brand.primary.500` : `purple.200`}
            >
              <Text
                sx={TextStyle}
                color={randomGenerated ? `white` : `brand.primary.500`}
              >
                {!randomGenerated ? "I'm feeling lucky 😉" : `₦ ${amount}`}
              </Text>
            </Box>
          </HStack>

          <Text fontSize={13} color="brand.secondary.500" mb="32px">
            This is a real debit amount
          </Text>
        </>
      )}

      <Button
        // mt="32px"
        size="lg"
        bg="brand.primary.500"
        color="white"
        _hover={{ bg: "#333" }}
        w="100%"
        onClick={handleSubmit}
        isLoading={isLoading}
        textTransform={"capitalize"}
      >
        Initiate {authorizationType}
      </Button>
    </Box>
  );
};
