import { Box, Heading } from "@chakra-ui/react";

import { MainContainer } from "../../layout";

export const ColoredBgText = () => {
  return (
    <MainContainer bg="transparent">
      <Box bg="brand.primary.700" pt="4.75rem" pb="4rem" borderRadius={"2rem"}>
        <Heading
          color="white"
          textAlign="center"
          fontSize={{ base: "2rem", xl: "2.5rem" }}
          px={{ base: "1.3rem", md: "2rem" }}
        >
          “We are re-imaging the everyday consumer mobile payment experience.”
        </Heading>
      </Box>
    </MainContainer>
  );
};
