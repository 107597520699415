import { useNavigate } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  Image,
  FormControl,
  FormLabel,
  Button,
  Input,
  SimpleGrid,
  Checkbox,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import * as yup from "yup";

import { MainContainer } from "../../layout";
import { makePostRequest } from "../../queries";
import WaitlistMockup from "../../assets/images/waitlistMockup.png";

export interface WaitlistData {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  acceptTerms: boolean;
}

const initialValues: WaitlistData = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  acceptTerms: false,
};

const validationSchema = yup.object({
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
  email: yup.string().required("Required").email("Email address is invalid"),
  phone_number: yup.string().required("Required"),
  acceptTerms: yup.boolean().required(""),
});

export const errorStyle = {
  borderWidth: "1px",
  borderColor: "red",
};

export const JoinWaitlistForm = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async (values: WaitlistData) => {
    try {
      const res = await makePostRequest(values, "user/waitlist");

      console.log(res);

      if (res.statusCode === 201) {
        toast({
          title: "Success",
          description: res.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/");
      } else
        toast({
          title: "An error occcured",
          description: res,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    } catch (err: any) {
      toast({
        title: "An error occcured",
        description: err,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.log(err);
    }

    // setLoading(true);

    // const res = await signup(values);

    // if (res.status === 201) {
    //   toast({
    //     title: "Success",
    //     description: res.data.message,
    //     status: "success",
    //     duration: 3000,
    //     isClosable: true,
    //   });
    //   navigate(`/email-verify?email=${values.email}`);
    // } else
    //   toast({
    //     title: "An error occcured",
    //     description: res,
    //     status: "error",
    //     duration: 3000,
    //     isClosable: true,
    //   });
    // setLoading(false);
  };

  return (
    <Box bg="white" pt={{ base: "48px", md: "96px" }} pb="64px" as="section">
      <MainContainer bg="transparent">
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 2fr" }}
          gap={{ base: "32px", md: "64px" }}
          alignItems="center"
        >
          <GridItem w="100%">
            <Heading color="brand.primary.500" fontSize="29px" mb="4px">
              Join Waitlist
            </Heading>

            <Text color="brand.secondary.500" mb="32px">
              Enter your details to create your account.
            </Text>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <SimpleGrid columns={2} gap={23}>
                    <FormControl w="100%">
                      <FormLabel
                        htmlFor="first_name"
                        fontSize={13}
                        color="brand.secondary.500"
                      >
                        First name
                      </FormLabel>
                      <Field
                        as={Input}
                        id="first_name"
                        name="first_name"
                        type="name"
                        sx={
                          touched.first_name && errors.first_name
                            ? errorStyle
                            : {}
                        }
                      />
                    </FormControl>

                    <FormControl w="100%">
                      <FormLabel
                        htmlFor="last_name"
                        fontSize={13}
                        color="brand.secondary.500"
                      >
                        Last name
                      </FormLabel>
                      <Field
                        as={Input}
                        id="last_name"
                        name="last_name"
                        type="name"
                        sx={
                          touched.last_name && errors.last_name
                            ? errorStyle
                            : {}
                        }
                      />
                    </FormControl>
                  </SimpleGrid>

                  <FormControl w="100%" my="16px">
                    <FormLabel fontSize={13} color="brand.secondary.500">
                      Company Email
                    </FormLabel>
                    <Field
                      as={Input}
                      id="email"
                      name="email"
                      type="email"
                      sx={touched.email && errors.email ? errorStyle : {}}
                    />
                  </FormControl>

                  <FormControl w="100%" my="16px">
                    <FormLabel fontSize={13} color="brand.secondary.500">
                      Phone Number
                    </FormLabel>
                    <Field
                      as={Input}
                      id="phone_number"
                      name="phone_number"
                      type="phone"
                      sx={
                        touched.phone_number && errors.phone_number
                          ? errorStyle
                          : {}
                      }
                    />
                  </FormControl>

                  <Box my="35px">
                    <Checkbox
                      size="lg"
                      iconColor="brand.primary.500"
                      colorScheme="brand.white"
                    >
                      <Flex wrap="wrap">
                        <Text fontSize={13} color="brand.secondary.500">
                          By continuing you agree to our
                        </Text>
                        <Box visibility="hidden">i</Box>
                        <Text fontSize={13} color="brand.primary.500">
                          Terms of Service
                        </Text>
                        <Box visibility="hidden">i</Box>
                        <Text fontSize={13} color="brand.secondary.500">
                          and
                        </Text>
                        <Box visibility="hidden">i</Box>
                        <Text fontSize={13} color="brand.primary.500">
                          Privacy Policy.
                        </Text>
                      </Flex>
                    </Checkbox>
                  </Box>

                  <Button w="100%" size="lg" type="submit">
                    Join the waitlist
                  </Button>
                </form>
              )}
            </Formik>
          </GridItem>

          <GridItem w="100%">
            <Image src={WaitlistMockup} alt="" />
          </GridItem>
        </Grid>
      </MainContainer>
    </Box>
  );
};
