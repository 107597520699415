import { Helmet } from "react-helmet";

import { Products } from "../features/demo";
import { MainLayout } from "../layout";

export const Demo = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CoAuth | Demo</title>
        <meta
          name="description"
          content={
            "This is the CoAuth demo page to test financial transactions"
          }
        />
      </Helmet>

      <MainLayout>
        <Products />
      </MainLayout>
    </div>
  );
};
