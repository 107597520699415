export const Brush: React.FC<any> = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.2441" cy="9.74359" r="9.74359" fill="#4ED17E" />
      <path
        d="M22.0474 21.9289L24.8758 19.1005L20.6332 14.8578L25.3473 10.1438L22.5188 7.31539L17.8048 12.0294L13.5621 7.78679L10.7337 10.6152L22.0474 21.9289ZM20.1618 23.8145L8.84811 12.5008L5.07687 16.2721L16.3905 27.5858L20.1618 23.8145ZM17.8048 8.25821L21.576 4.48697C22.0968 3.96626 22.9409 3.96626 23.4616 4.48697L28.1757 9.20101C28.6964 9.72171 28.6964 10.5659 28.1757 11.0866L24.4045 14.8578L27.7042 18.1577C28.225 18.6784 28.225 19.5226 27.7042 20.0433L17.3334 30.4142C16.8126 30.9349 15.9685 30.9349 15.4477 30.4142L2.24844 17.2149C1.72773 16.6942 1.72773 15.85 2.24844 15.3293L12.6193 4.95837C13.14 4.43767 13.9842 4.43767 14.5049 4.95837L17.8048 8.25821Z"
        fill="black"
      />
    </svg>
  );
};
