export const User: React.FC<any> = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.2446" cy="9.74359" r="9.74359" fill="#4ED17E" />
      <path
        d="M4 7.32681C4 5.85766 5.19016 4.66669 6.66012 4.66669H25.3399C26.8091 4.66669 28 5.85685 28 7.32681V26.0066C28 27.4758 26.8099 28.6667 25.3399 28.6667H6.66012C5.19097 28.6667 4 27.4766 4 26.0066V7.32681ZM6.66667 7.33335V26H25.3333V7.33335H6.66667ZM10.6295 24.9078C9.80463 24.5506 9.02292 24.1124 8.29444 23.6032C9.95697 21.0338 12.848 19.3334 16.1363 19.3334C19.3353 19.3334 22.1585 20.9428 23.8401 23.3963C23.1251 23.9248 22.3552 24.3836 21.5407 24.7624C20.3296 23.0888 18.36 22 16.1363 22C13.8487 22 11.8303 23.1522 10.6295 24.9078ZM16 18C13.4227 18 11.3333 15.9107 11.3333 13.3334C11.3333 10.756 13.4227 8.66669 16 8.66669C18.5773 8.66669 20.6667 10.756 20.6667 13.3334C20.6667 15.9107 18.5773 18 16 18ZM16 15.3334C17.1045 15.3334 18 14.4379 18 13.3334C18 12.2288 17.1045 11.3334 16 11.3334C14.8955 11.3334 14 12.2288 14 13.3334C14 14.4379 14.8955 15.3334 16 15.3334Z"
        fill="black"
      />
    </svg>
  );
};
