import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Business, Demo, DemoType, Home, JoinWaitlist, Privacy } from "./pages";

export const WebRoutes = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route index path="/" element={<Business />} />
          <Route path="join-waitlist" element={<JoinWaitlist />} />
          <Route path="demo" element={<Demo />} />
          <Route path="demo/authorization" element={<DemoType />} />
          <Route path="consumers" element={<Home />} />
          <Route path="privacy" element={<Privacy />} />
        </Routes>
      </Router>
    </>
  );
};
