export const Security: React.FC<any> = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.2441" cy="9.74359" r="9.74359" fill="#4ED17E" />
      <path
        d="M24.0067 27.3334H8.00667V30.0001H5.34001V27.3334H4.00667C3.2703 27.3334 2.67334 26.7365 2.67334 26.0001V6.00008C2.67334 5.26371 3.2703 4.66675 4.00667 4.66675H28.0067C28.7431 4.66675 29.34 5.26371 29.34 6.00008V26.0001C29.34 26.7365 28.7431 27.3334 28.0067 27.3334H26.6734V30.0001H24.0067V27.3334ZM5.34001 24.6667H26.6734V7.33341H5.34001V24.6667ZM17.34 19.1654V23.3334H14.6734V19.1654C12.373 18.5733 10.6733 16.4851 10.6733 14.0001C10.6733 11.0546 13.0612 8.66675 16.0067 8.66675C18.9522 8.66675 21.34 11.0546 21.34 14.0001C21.34 16.4851 19.6403 18.5733 17.34 19.1654ZM16.0067 16.6667C17.4795 16.6667 18.6734 15.4729 18.6734 14.0001C18.6734 12.5273 17.4795 11.3334 16.0067 11.3334C14.5339 11.3334 13.34 12.5273 13.34 14.0001C13.34 15.4729 14.5339 16.6667 16.0067 16.6667Z"
        fill="black"
      />
    </svg>
  );
};
