import {
  Box,
  Flex,
  HStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";

import { Logo } from "./Logo";
import { MainContainer } from "../layout";

export const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const active = {
    color: "black",
    textDecoration: "underline",
    textUnderlineOffset: "3px",
  };

  const inactive = {
    color: "brand.gray.500",
    textDecoration: "none",
  };

  return (
    <MainContainer bg="transparent">
      <Box pb="48px">
        <Flex
          alignItems={{ base: "flex-start", md: "flex-end" }}
          justifyContent="space-between"
          flexDir={{ base: "column", md: "row" }}
          gap="32px"
        >
          <Box>
            <Logo onPress={() => navigate("/")} />

            <HStack mt="32px" display={{ base: "none", md: "flex" }}>
              <Text
                fontSize={"16px"}
                sx={location.pathname === "/consumers" ? active : inactive}
                mr="32px"
                onClick={() => navigate("/consumers")}
                cursor={"pointer"}
                _hover={{ textDecoration: "underline", textUnderlineOffset: "3px" }}
              >
                Consumers
              </Text>

              <Text
                fontSize={"16px"}
                sx={location.pathname === "/" ? active : inactive}
                mr="32px"
                onClick={() => navigate("/")}
                cursor={"pointer"}
                _hover={{ textDecoration: "underline", textUnderlineOffset: "3px" }}
              >
                Business
              </Text>

              {/* <Text fontSize={"16px"} color="brand.gray.400" mr="32px">
                FAQs
              </Text> */}
            </HStack>
          </Box>

          <FormControl w={{ base: "100%", md: "auto" }}>
            <FormLabel fontSize={"14px"} color="brand.gray.900">
              Stay up to date
            </FormLabel>
            <Flex gap="16px" flexDir={{ base: "column", md: "row" }}>
              <Input type="email" placeholder="Enter your email" w="auto" />
              <Button
                bg="brand.primary.500"
                color="white"
                _hover={{ bg: "brand.primary.500" }}
              >
                Subscribe
              </Button>
            </Flex>
          </FormControl>
        </Flex>

        <Box
          h="1px"
          w="100%"
          bg="brand.gray.200"
          mt={{ base: "32px", md: "64px" }}
          mb="32px"
        />

        <Flex
          justifyContent="space-between"
          flexDir={{ base: "column-reverse", md: "row" }}
          gap="16px"
        >
          <Text color="brand.gray.400" fontSize={"16px"}>
            © 2023 CoAuth. All rights reserved.
          </Text>

          <HStack>
            {/* <Text color="brand.gray.400" fontSize={"16px"}>
              Terms
            </Text> */}

            <Text
              sx={location.pathname === "/privacy" ? active : inactive}
              // pl="16px"
              fontSize={"16px"}
              cursor={"pointer"}
              onClick={() => navigate("/privacy")}
              _hover={{ textDecoration: "underline", textUnderlineOffset: "3px" }}
            >
              Policy
            </Text>

            {/* <Text color="brand.gray.400" pl="16px" fontSize={"16px"}>
              Cookies
            </Text> */}
          </HStack>
        </Flex>
      </Box>
    </MainContainer>
  );
};
