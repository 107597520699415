export const formatCurrency = (number: any, fixed = 0) => {
    const fixedValue = !Number.isInteger(number) ? 2 : fixed;
    return fixedValue > 0
      ? number && number.toFixed(fixedValue).replace(/\d(?=(\d{3})+\.)/g, "$&,")
      : Math.round(((number + Number.EPSILON) * 100) / 100)
          .toString()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  };
  
  export const formatDate = (date: string) => {
    const inputDate = new Date(date);
  
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  
    const day = inputDate.getDate();
    const month = months[inputDate.getMonth()];
    const year = inputDate.getFullYear();
  
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
  
    const formattedDate = `${day} ${month} ${year}, ${formattedHours}:${formattedMinutes} ${amPm}`;
    return formattedDate;
  };