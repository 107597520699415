import { ChakraProvider } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import { theme } from "./config/theme";
import { WebRoutes } from "./routes";
import "./App.css";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <meta property="og:title" content={"CoAuth"} />
        <meta
          property="og:description"
          content={
            "CoAuth is a secure and trustworthy way for customers to link their financial accounts."
          }
        />
        <meta property="og:image" content={"./assets/images/icon.png"} />
        <meta property="og:url" content={"https://coauth.app"} />
        <meta property="og:type" content="website" />
      </Helmet>

      <WebRoutes />
    </ChakraProvider>
  );
}

export default App;
