import {
  Box,
  Heading,
  Text,
  Image,
  Icon,
  Button,
  HStack,
  Grid,
  GridItem,
  Flex,
} from "@chakra-ui/react";
import { FiPlayCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { MainContainer } from "../../layout";

import AppStore from "../../assets/images/appStore.png";
import PlayStore from "../../assets/images/playStore.png";
import AuthorizationPhone from "../../assets/images/authorizationPhone.png";

export const Hero = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <MainContainer bg="transparent">
        <Grid
          py="3rem"
          gap={{ base: "3rem", md: "4rem" }}
          gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }}
          alignItems="center"
        >
          <GridItem display={{ base: "block", md: "none" }}>
            <Heading
              fontSize={{ base: "2rem", xl: "3rem" }}
              color="brand.gray.900"
              mb="4rem"
            >
              Spend, authorise and manage your payments, all in one place.
            </Heading>
            <Flex
              align="center"
              justify="center"
              display={{ base: "flex", md: "none" }}
            >
              <Image
                src={AuthorizationPhone}
                alt="phone"
                // h={{ base: "488px", md: "640px" }}
              />
            </Flex>
          </GridItem>

          <GridItem>
            <Heading
              fontSize={{ base: "2rem", xl: "3rem" }}
              color="brand.gray.900"
              // mt={{ base: "0", md: "4rem" }}
              display={{ base: "none", md: "block" }}
            >
              Spend, authorise and manage your payments, all in one place.
            </Heading>

            <Flex
              my={{ base: "0", md: "3rem" }}
              gap="0.8rem"
              flexDir={{ base: "column", md: "row" }}
            >
              <Button
                color="brand.gray.500"
                border="1px"
                borderColor="brand.gray.300"
                bg="white"
                size="lg"
                leftIcon={
                  <Icon
                    as={FiPlayCircle}
                    color="brand.gray.700"
                    w="1.5rem"
                    h="1.5rem"
                  />
                }
                onClick={() => navigate("/demo")}
              >
                Demo
              </Button>

              <Button
                bg="brand.primary.500"
                color="white"
                size="lg"
                _hover={{ bg: "brand.primary.500" }}
                onClick={() => navigate("/join-waitlist")}
              >
                Join waitlist
              </Button>
            </Flex>

            <Text color="brand.gray.500" fontSize="1rem" mt="3rem">
              Will also be available on
            </Text>

            <HStack mt="0.5rem">
              <a
                href={`${process.env.REACT_APP_APP_STORE}`}
                target="_blank"
                rel="noreferrer"
              >
                <Image src={AppStore} alt="" h="3rem" />
              </a>

              <a
                href={`${process.env.REACT_APP_PLAY_STORE}`}
                target="_blank"
                rel="noreferrer"
              >
                <Image src={PlayStore} alt="" h="3rem" />
              </a>
            </HStack>
          </GridItem>

          <GridItem>
            <Flex
              align="center"
              justify="center"
              display={{ base: "none", md: "flex" }}
            >
              <Image
                src={AuthorizationPhone}
                alt="phone"
                objectFit={"contain"}
                // h="640px"
              />
            </Flex>
          </GridItem>
        </Grid>
      </MainContainer>
    </Box>
  );
};
