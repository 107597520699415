import { Helmet } from "react-helmet";

import {
  ColoredBgText,
  // Faq,
  Features,
  Future,
  // GetInTouch,
  Hero,
  // PaymentData,
  Encryption,
  PaymentAuthorisation,
  // ValuePropostion,
} from "../features/home";
import { MainLayout } from "../layout";

export const Home = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CoAuth | Consumers</title>
        <meta
          name="description"
          content={
            "This is the CoAuth consumers page that shows the features we have for personal users"
          }
        />
      </Helmet>

      <MainLayout>
        <Hero />
        <Features />
        <ColoredBgText />
        <Encryption />
        <PaymentAuthorisation />
        {/* <PaymentData /> */}
        {/* <ValuePropostion /> */}
        {/* <Faq /> */}
        {/* <GetInTouch /> */}
        <Future
          header="Let’s build the future of consumer mobile payment together"
          body="Integrate CoAuth to offer your customers a more secure and delightful
          mobile payment checkout experience."
        />
      </MainLayout>
    </div>
  );
};
