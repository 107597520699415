import { Box, Heading, Text, Flex, Image, SimpleGrid } from "@chakra-ui/react";

import { MainContainer } from "../../layout";
// import AuthorizationPhone from "../../assets/images/authorizationPhone.png";
import iPhone14 from "../../assets/images/iPhone14.png";
import iPhone15 from "../../assets/images/iPhone15.png";
import { Wallet } from "../../assets/svg/Wallet";
import { Shield } from "../../assets/svg/Shield";

export const PaymentAuthorisation = () => {
  const list = [
    {
      id: 1,
      svg: <Wallet />,
      header: "Payment Options",
      body: "Pay how you want, with a card, account or wallet. Pay online, in-store, in-app.",
      image: iPhone14,
    },
    {
      id: 2,
      svg: <Shield />,
      header: "Request Payment",
      body: "Request for your bills, then securely authorise the payment request.",
      image: iPhone15,
    },
  ];

  return (
    <MainContainer bg="transparent">
      <SimpleGrid gap="2rem" columns={{ base: 1, lg: 2 }}>
        {list.map((item) => (
          <Box
            bg="brand.secondary.50"
            px={{ base: "3rem", md: "4rem" }}
            pt={{ base: "3rem", md: "4rem" }}
            borderRadius={"2rem"}
            mb="6rem"
            as="section"
            key={item.id}
          >
            <Box>
              <Flex
                borderRadius="1rem"
                bg="white"
                w="4rem"
                h="4rem"
                align="center"
                justify="center"
              >
                {item.svg}
              </Flex>

              <Heading
                color="brand.gray.900"
                fontSize={{ base: "1.8rem", xl: "2rem" }}
                my="0.8rem"
              >
                {item.header}
              </Heading>

              <Text
                color="brand.gray.500"
                fontSize={{ base: "1rem", xl: "1.1rem" }}
              >
                {item.body}
              </Text>
            </Box>

            <Flex justify={"center"}>
              <Image
                src={item.image}
                alt=""
                h={{ base: "350px", md: "450px" }}
                backgroundImage={"none"}
                mt="4rem"
              />
            </Flex>
          </Box>
        ))}
      </SimpleGrid>
    </MainContainer>
  );
};
