import { Checkout } from "../features/demo-type";
import { MainLayout } from "../layout";

export const DemoType = () => {
  return (
    <div>
      <MainLayout>
        <Checkout />
      </MainLayout>
    </div>
  );
};
