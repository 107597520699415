import { Box, Flex, Heading, Text, Button, Icon } from "@chakra-ui/react";
import { FiPlayCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { MainContainer } from "../../layout";

export const Future = ({ header, body }: any) => {
  const navigate = useNavigate();

  return (
    <MainContainer bg="transparent">
      <Box
        bg="brand.primary.700"
        pt={{ base: "3rem", lg: "4.5rem" }}
        pb={{ base: "3rem", lg: "4rem" }}
        px={{ base: "1.5rem", lg: "3rem" }}
        borderRadius={"2rem"}
        mb="6rem"
      >
        <Heading
          color="white"
          textAlign="center"
          fontSize={{ base: "2rem", xl: "2.25rem" }}
        >
          {header}
        </Heading>

        <Text
          color="white"
          fontSize={{ base: "1rem", md: "1.2rem" }}
          mt={{ base: "1.5rem", md: "0.5rem" }}
          mb="2rem"
          textAlign="center"
        >
          {body}
        </Text>

        <Flex
          justify="center"
          flexDir={{ base: "column", md: "row" }}
          gap="0.8rem"
        >
          <Button
            color="brand.gray.500"
            border="1px"
            borderColor="brand.gray.300"
            bg="white"
            size="lg"
            leftIcon={
              <Icon
                as={FiPlayCircle}
                color="brand.gray.700"
                w="1.5rem"
                h="1.5rem"
              />
            }
            onClick={() => navigate("/demo")}
          >
            Demo
          </Button>

          <Button
            bg="brand.primary.300"
            color="white"
            size="lg"
            _hover={{ color: "brand.primary.500" }}
            onClick={() => navigate("/join-waitlist")}
          >
            Join waitlist
          </Button>
        </Flex>

        {/* <Flex justify="center" mt="64px">
        <Image src={FutureMockup} alt="" />
      </Flex> */}
      </Box>
    </MainContainer>
  );
};
