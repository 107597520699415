import { Box, Heading, Text, Flex, Image, SimpleGrid } from "@chakra-ui/react";

import { MainContainer } from "../../layout";
import PaymentAccounts from "../../assets/images/paymentAccounts.png";
import DataAuthentications from "../../assets/images/dataAuthentications.png";
import { Card } from "../../assets/svg/Card";
import { User } from "../../assets/svg/User";

export const PaymentData = () => {
  const list = [
    {
      id: 1,
      svg: <Card />,
      header: "Payment",
      body: "Secure and chargeback-free payment method directly through your customer’s banking app, with instant settlements and refund.",
      image: PaymentAccounts,
    },
    {
      id: 2,
      svg: <User />,
      header: "Data",
      body: "Utilize financial account information to innovate and enhance the user experience.",
      image: DataAuthentications,
    },
  ];

  return (
    <MainContainer bg="transparent">
      <Heading
        color="brand.gray.900"
        fontSize={{ base: "1.8rem", xl: "2.5rem" }}
        w={{ base: "100%", md: "55%" }}
        mb="2rem"
      >
        Build with open banking for banks, merchants and startups
      </Heading>

      <SimpleGrid gap="2rem" columns={{ base: 1, lg: 2 }} mb="4rem">
        {list.map((item) => (
          <Flex
            bg="brand.secondary.50"
            pl={{ base: "3rem", md: "4rem" }}
            pt={{ base: "3rem", md: "4rem" }}
            borderRadius={"2rem"}
            borderWidth="1px"
            borderColor="brand.secondary.100"
            mb="2rem"
            as="section"
            key={item.id}
            flexDir="column"
            justify={"space-between"}
          >
            <Box pr={{ base: "3rem", md: "4rem" }}>
              <Flex
                borderRadius="1rem"
                bg="white"
                w="4rem"
                h="4rem"
                align="center"
                justify="center"
              >
                {item.svg}
              </Flex>

              <Heading
                color="brand.gray.900"
                fontSize={{ base: "1.8rem", md: "2rem" }}
                fontWeight={700}
                my="0.8rem"
              >
                {item.header}
              </Heading>

              <Text
                color="brand.gray.500"
                fontSize={{ base: "1rem", md: "1.3rem" }}
              >
                {item.body}
              </Text>
            </Box>

            <Flex justify={"flex-end"}>
              <Image
                src={item.image}
                alt=""
                mt={{ base: "3rem", md: "4rem" }}
                borderBottomRightRadius={"2rem"}
              />
            </Flex>
          </Flex>
        ))}
      </SimpleGrid>
    </MainContainer>
  );
};
