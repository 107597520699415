import { Heading, Text, Flex, Icon, Box } from "@chakra-ui/react";

export type FeatureProps = {
  id: number;
  icon: any;
  title: string;
  body: string;
  svg?: boolean;
};

export const Feature: React.FC<FeatureProps> = ({
  id,
  icon,
  title,
  body,
  svg = false,
}) => {
  return (
    <Box
      key={id}
      bg="brand.secondary.50"
      p={{ base: "3rem", lg: "4rem" }}
      borderRadius={"2rem"}
      h="100%"
      // minW={"370px"}
    >
      <Flex
        borderRadius="1rem"
        bg="white"
        w="4rem"
        h="4rem"
        align="center"
        justify="center"
      >
        {svg ? icon : <Icon as={icon} w="2rem" h="2rem" />}
      </Flex>

      <Heading
        color="brand.gray.900"
        fontSize={{ base: "1.8rem", xl: "2rem" }}
        fontWeight={700}
        my="1rem"
        // wordBreak={'break-word'}
        // w="85%"
      >
        {title}
      </Heading>

      <Text fontSize={"1.1rem"} color="brand.gray.500">
        {body}
      </Text>
    </Box>
  );
};
