export const Shield: React.FC<any> = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.2441" cy="9.74359" r="9.74359" fill="#4ED17E" />
      <path
        d="M16 2.00003L26.9559 4.43467C27.566 4.57024 28 5.11132 28 5.73626V19.0519C28 21.7267 26.6632 24.2246 24.4376 25.7083L16 31.3334L7.5624 25.7083C5.33681 24.2246 4 21.7267 4 19.0519V5.73626C4 5.11132 4.43404 4.57024 5.04409 4.43467L16 2.00003ZM16 4.73175L6.66667 6.80582V19.0519C6.66667 20.8351 7.55787 22.5003 9.0416 23.4895L16 28.1284L22.9584 23.4895C24.4421 22.5003 25.3333 20.8351 25.3333 19.0519V6.80582L16 4.73175ZM16 10C17.4728 10 18.6667 11.1939 18.6667 12.6667C18.6667 13.6532 18.1311 14.5144 17.3348 14.9758L17.3333 20.6667H14.6667L14.6665 14.9766C13.8696 14.5155 13.3333 13.6537 13.3333 12.6667C13.3333 11.1939 14.5272 10 16 10Z"
        fill="black"
      />
    </svg>
  );
};
