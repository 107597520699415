// import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Heading,
  Text,
  Flex,
  Box,
  Button,
  Image,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon, CheckIcon } from "@chakra-ui/icons";

import { formatCurrency, formatDate } from "../utils";
import Success from "../assets/images/success.png";
import BlankTransaction from "../assets/images/blankTransaction.png";

export type DemoSuccessProps = {
  amount: string | null;
  authorizationType: string | null;
  data: string;
};

export const DemoSuccess: React.FC<DemoSuccessProps> = ({
  amount,
  authorizationType,
  data,
}) => {
  const navigate = useNavigate();

  const decodedData = decodeURIComponent(data);
  const dataArray = JSON.parse(decodedData);

  console.log(dataArray);

  const ContainerStyle = {
    borderRadius: "16px",
    borderWidth: "1px",
    borderColor: "brand.primary.50",
    bg: "#F9F9F9",
  };

  const HeaderStyle = {
    fontSize: "28px",
    color: "brand.primary.500",
    textAlign: "center",
    px: "20%",
    mt: "40px",
  };

  return (
    <Box sx={ContainerStyle} px={{ base: "16px", md: "48px" }} py="48px">
      <ArrowBackIcon
        boxSize={"24px"}
        color={"black"}
        mb="24px"
        cursor={"pointer"}
        onClick={() =>
          navigate(
            `/demo/authorization?authorizationType=${authorizationType}&type=existing`
          )
        }
      />

      <Flex align="center" gap="8px" mb="36px">
        <Flex
          align="center"
          justify="center"
          bg="brand.success.600"
          w={{ base: "16px", md: "20px" }}
          h={{ base: "16px", md: "20px" }}
          borderRadius="10px"
        >
          <CheckIcon boxSize={{ base: "9px", md: "11px" }} color={"white"} />
        </Flex>

        <Text fontSize={{ base: "9px", md: "11px" }} color="brand.success.600">
          Initiate
        </Text>

        <Box w="30px" h="1px" bg="brand.success.600" />

        <Flex
          align="center"
          justify="center"
          bg="brand.success.600"
          w={{ base: "16px", md: "20px" }}
          h={{ base: "16px", md: "20px" }}
          borderRadius="10px"
        >
          <CheckIcon boxSize={{ base: "9px", md: "11px" }} color={"white"} />
        </Flex>

        <Text fontSize={{ base: "9px", md: "11px" }} color="brand.success.600">
          Authorise
        </Text>

        <Box w="30px" h="1px" bg="brand.success.600" />

        <Flex
          align="center"
          justify="center"
          bg="brand.primary.500"
          w={{ base: "16px", md: "20px" }}
          h={{ base: "16px", md: "20px" }}
          borderRadius="10px"
        >
          <Text
            color="white"
            fontSize={{ base: "9px", md: "11px" }}
            fontWeight={600}
          >
            3
          </Text>
        </Flex>

        <Text fontSize={{ base: "9px", md: "11px" }} color="brand.primary.500">
          Success
        </Text>
      </Flex>

      {authorizationType === "payment" ? (
        <Box mt="36px">
          <Flex justify="center">
            <Image src={Success} alt="" w="200px" />
          </Flex>
          <Heading sx={HeaderStyle}>Success</Heading>
          <Flex
            wrap={"wrap"}
            fontSize={16}
            fontWeight={600}
            color={"brand.secondary.500"}
            px="10%"
            mt="8px"
            justify={"center"}
          >
            <Text>Your transaction of</Text>
            <Text color="brand.primary.500" px="5px">
              ₦{formatCurrency(Number(amount), 2)}
            </Text>
            <Text>was authorised successfully.</Text>
          </Flex>
        </Box>
      ) : (
        dataArray.map((transaction: any, index: any) => (
          <Flex
            align="center"
            justify="space-between"
            borderBottomWidth="1px"
            borderBottomColor="brand.secondary.50"
            py="16px"
            key={index}
          >
            <HStack gap="16px">
              <Image
                src={BlankTransaction}
                w="48px"
                h="48px"
                borderRadius="50%"
              />

              <Box>
                <Heading
                  fontSize={16}
                  fontWeight={600}
                  color="brand.primary.500"
                >
                  Coauth
                </Heading>

                <Text
                  fontSize={13}
                  color="brand.primary.500"
                  textTransform={"capitalize"}
                >
                  {transaction.status}.{" "}
                  {formatDate(transaction.transaction_date)}
                </Text>
              </Box>
            </HStack>

            <Box>
              <Heading
                fontSize={14}
                fontWeight={600}
                color="brand.primary.500"
                textAlign={"right"}
              >
                ₦{formatCurrency(Number(transaction.amount), 2)}
              </Heading>

              <Text fontSize={13} color="brand.primary.500" textAlign={"right"}>
                • • • • {transaction.card.last4}
              </Text>
            </Box>
          </Flex>
        ))
      )}

      <Button
        mt="32px"
        size="lg"
        bg="brand.primary.500"
        color="white"
        _hover={{ bg: "#333" }}
        w="100%"
        onClick={() => navigate("/")}
      >
        Done
      </Button>

      <Button
        mt="16px"
        size="lg"
        variant={"ghost"}
        color="brand.primary.500"
        borderWidth={"1px"}
        borderColor={"brand.primary.500"}
        w="100%"
        onClick={() => navigate("/demo")}
      >
        Begin Another Demo
      </Button>
    </Box>
  );
};
